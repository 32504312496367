import "styled-components";
import styled from "styled-components";

export const SoundcloudWrapper = styled.div`
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  @media only screen and (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
`;

export const Player = styled.div`
  margin: 10px;
`;
