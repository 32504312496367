import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Mixes from "../containers/mixes";

type MixesPageProps = {};

const MixesPage: React.FunctionComponent<MixesPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="Mixes"
        description="Mixes de reggaeton, urbano, variados, música de matrimonio."
      />

      <Mixes />
    </Layout>
  );
};

export default MixesPage;
