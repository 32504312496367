import styled from "styled-components";

export const MixesWrapper = styled.div`
  padding-bottom: 95px;
`;

export const MixesTitle = styled.h2``;

export const MixesSubTitle = styled.h3``;

export const MixesImage = styled.div``;
