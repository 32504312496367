import { SoundcloudWrapper, Player } from "./style";
import React from "react";
import SoundCloudWidget from "react-simple-soundcloud-widget";

type SoundcloudProps = { mixes: array<string> };

const Soundcloud: React.FunctionComponent<SoundcloudProps> = ({ ...props }) => {
  //var result = props.mixes.map((mix) => ({ uri: mix }));
  console.log(props.mixes);
  return (
    <SoundcloudWrapper>
      {props.mixes.map((mix) => (
        <Player>
          <SoundCloudWidget url={mix} />
        </Player>
      ))}
    </SoundcloudWrapper>
  );
};

export default Soundcloud;
