import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MixesWrapper, MixesTitle, MixesSubTitle, MixesImage } from "./style";
import Image from "gatsby-image";
import Soundcloud from "../../components/soundcloud";
import Fade from "react-reveal/Fade";
import Button from "../../components/button";
type MixesProps = {};

const Mixes: React.FunctionComponent<MixesProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/dj-paul-mixes.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);
  return (
    <MixesWrapper>
      <MixesImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </MixesImage>
      <Fade right>
        <MixesTitle>escucha</MixesTitle>
        <MixesSubTitle>todos mis mixes 🎧</MixesSubTitle>
        <Soundcloud
          mixes={[
            "https://soundcloud.com/user-697656371-419240302/mix-buena-bulla-vol-2",
            "https://soundcloud.com/user-697656371-419240302/cochimix-en-do-menor-ft-dj-cano",
            "https://soundcloud.com/user-697656371-419240302/mix-party-vibes",
            "https://soundcloud.com/user-697656371-419240302/dj-paul-mix-finde",
            "https://soundcloud.com/user-697656371-419240302/mix-buena-bulla",
            "https://soundcloud.com/user-697656371-419240302/dj-paul-mix-desmadre-dos",
            "https://soundcloud.com/user-697656371-419240302/mix-latin-2",
            "https://soundcloud.com/user-697656371-419240302/mix-latin-1",
            "https://soundcloud.com/user-697656371-419240302/mix-desmadre",
            "https://soundcloud.com/user-697656371-419240302/dj-paul-mix-motopapi",
          ]}
        />
      </Fade>
    </MixesWrapper>
  );
};

export default Mixes;
